import { FC } from 'react';
import styled from '@emotion/styled';
import { Grid } from '@chakra-ui/react';
import { MhyChakraProps } from '../types';

const StyledGrid = styled(Grid)`
  grid-template-columns:
    1fr min(calc(100% - 2 * var(--mhy-space-5)), var(--container-lg))
    1fr;
  > * {
    grid-column: 2;
    grid-template-columns: 100%;
    width: 100%; /* browser thing */
  }
`;

const ArticleContainer: FC<MhyChakraProps> = (props) => (
  <StyledGrid
    gridRowGap={[8, 10, 14]}
    as="article"
    mb="14"
    {...props}
    data-test-id="article-grid"
  />
);

export const RelatedContainer: FC<MhyChakraProps> = (props) => (
  <Grid as="section" gridRowGap={[8, 10, 14]} {...props} />
);

export default ArticleContainer;
