import { ReactNode } from 'react';
import { Heading as ChakraHeading } from '@chakra-ui/react';
import { MhyChakraProps } from '../types';
import { getTextChildren } from '../lib/utils';

interface HeadingProps extends MhyChakraProps {
  className?: string;
  children?: ReactNode;
}

const headingElements = ['h1', 'h2', 'h3', 'h4', 'h5'];

const getVariantByClassname = (className?: string) => {
  if (!className || !className.includes('is-style-')) {
    return 'h2';
  }
  const wpVariantClassName = className
    .split(' ')
    .find((style) => headingElements.includes(style.replace('is-style-', '')));

  return wpVariantClassName?.replace('is-style-', '') || 'h2';
};

const Heading = ({
  className,
  variant,
  as: headingAs,
  ...rest
}: HeadingProps) => {
  /**
   * If heading style is defined in Gutenberg Heading block
   * we extract it from className and use it as variant prop.
   *
   * But only before we check if variant is set by hand
   * Or there's 'as' included which is a heading type of 'as' and then use it
   */

  const resolvedVariant =
    variant ||
    (headingAs && headingElements.includes(headingAs as string)
      ? (headingAs as string)
      : getVariantByClassname(className));

  return (
    <ChakraHeading
      variant={resolvedVariant}
      as={headingAs}
      {...getTextChildren(rest)}
    />
  );
};

export default Heading;
